import { FetchRequest } from '@rails/request.js'

// Button to show unfinished nodes in view
class NodeHunterControl {
  constructor (mapboxNodeHunterPopup, supporter) {
    this._mapboxNodeHunterPopup = mapboxNodeHunterPopup
    this._supporter = supporter
  }

  onAdd (map) {
    this._container = document.createElement('div')
    this._container.classList.add('mapboxgl-ctrl')
    this._container.classList.add('mapboxgl-ctrl-group')

    const button = document.createElement('button')
    button.classList.add('mapboxgl-ctrl-icon')
    button.classList.add('plausible-event-name=NodeHunterControl')
    button.setAttribute('title', 'Node Hunter')
    button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="text-purple-900 h-6 w-6 pl-1"><path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z" clip-rule="evenodd" /></svg>'

    this._container.appendChild(button)
    const nodeInfoPopup = this._mapboxNodeHunterPopup

    button.addEventListener('debounced:click', () => {
      if (!this._supporter) {
        window.Turbo.visit('/supporter-features/node-hunter')
        return
      }

      if (map.getLayer('CityStrides-unfinishedNodes')) {
        map.removeLayer('CityStrides-unfinishedNodes')
        map.removeSource('CityStrides-unfinishedNodes')
        nodeInfoPopup.remove()
      }

      if (!document.getElementById('reset-node-hunter')) {
        const resetButton = document.createElement('button')
        resetButton.classList.add('mapboxgl-ctrl-icon')
        resetButton.setAttribute('title', 'Reset Node Hunter')
        resetButton.setAttribute('id', 'reset-node-hunter')
        resetButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="fill-current text-red-500 w-6 h-6 pl-1"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>'
        this._container.appendChild(resetButton)

        resetButton.addEventListener('debounced:click', () => {
          resetButton.remove()
          nodeInfoPopup.remove()

          if (map.getLayer('CityStrides-unfinishedNodes')) {
            map.removeLayer('CityStrides-unfinishedNodes')
            map.removeSource('CityStrides-unfinishedNodes')
          }
        })
      }

      const currentBounds = map.getBounds()
      const cityId = document.location.pathname.includes('cities') ? document.location.pathname.split('/').pop() : 0
      const challengeId = document.location.pathname.includes('challenges') ? document.location.pathname.split('/').pop() : 0
      // ! Does not allow for a Crew-specific Challenge
      const crewId = document.location.pathname.includes('crews') ? document.location.pathname.split('/')[2] : 0
      const request = new FetchRequest('get', '/nodes.json', {
        contentType: 'application/json',
        query: { challenge: challengeId, city: cityId, crew: crewId, nelng: currentBounds._ne.lng, nelat: currentBounds._ne.lat, swlng: currentBounds._sw.lng, swlat: currentBounds._sw.lat },
        responseKind: 'json'
      })
      request.perform().then(response => {
        return response.json
      }).then(data => {
        if (data.length > 999 && !document.cookie.includes('hideNodeHunterLimit')) {
          const message = '<p>There is a 1,000 Node limit. You <strong>may</strong> need to zoom in to see them all.</p><p class="mt-4"><button data-action="click->notifications#hideNodeHunterLimit" class="inline-flex items-center justify-center px-4 py-2 mr-2 text-sm font-medium text-zinc-700 bg-white border border-zinc-300 rounded-md shadow-sm hover:bg-zinc-50 focus:border-purple-900 focus:bg-white focus:outline-none focus:ring-1 focus:ring-purple-500">Don\'t alert me again</button></p>'
          const event = new window.CustomEvent('notifications:toast', { detail: { content: message, type: 'error' } })
          document.dispatchEvent(event)
        }

        const featureCollection = []
        for (let i = 0; i < data.length; i++) {
          featureCollection.push({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [data[i][1], data[i][0]]
            },
            properties: {
              street_name: data[i][3],
              nodes_count: data[i][4],
              city_name: data[i][5],
              street_id: data[i][6],
              city_id: data[i][7]
            }
          })
        }

        if (map.getLayer('CityStrides-unfinishedNodes')) {
          map.removeLayer('CityStrides-unfinishedNodes')
          map.removeSource('CityStrides-unfinishedNodes')
          // nodeInfoPopup.remove()
        }

        map.addSource('CityStrides-unfinishedNodes', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: featureCollection
          }
        })
        map.addLayer({
          id: 'CityStrides-unfinishedNodes',
          type: 'circle',
          source: 'CityStrides-unfinishedNodes',
          paint: {
            'circle-color': '#ef4444',
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff',
            'circle-radius': 8
          }
        }, 'CityStrides-routeBuilder')

        map.on('mouseenter', 'CityStrides-unfinishedNodes', () => {
          if (document.getElementById('mapContainer').getAttribute('data-map-currently-drawing-value') === 'true') {
            return
          }

          map.getCanvas().style.cursor = 'pointer'
        })

        map.on('mouseleave', 'CityStrides-unfinishedNodes', () => {
          if (document.getElementById('mapContainer').getAttribute('data-map-currently-drawing-value') === 'true') {
            return
          }

          map.getCanvas().style.cursor = 'grab'
        })

        map.on('click', 'CityStrides-unfinishedNodes', function (e) {
          if (document.getElementById('mapContainer').getAttribute('data-map-currently-drawing-value') === 'true') {
            return
          }

          let popupContents = '<div class="space-y-4">'

          // Only include details for Nodes of a unique street
          // This keeps the popup from containing too much info when you zoom out and they start overlapping
          const uniqueNodes = e.features.filter((value, index, self) => {
            return self.findIndex((n) => n.properties.street_id === value.properties.street_id) === index
          })

          Object.values(uniqueNodes).forEach((val) => {
            popupContents += `
            <div>
              <div class="leading-tight text-lg">
                <a class="text-link" href="https://citystrides.com/streets/${val.properties.street_id}">${val.properties.street_name}</a>
              </div>
              <div class="text-sm font-semibold">
                <a class="text-link" href="https://citystrides.com/cities/${val.properties.city_id}">${val.properties.city_name}</a>
              </div>
              <div class="text-xs font-normal">${val.properties.nodes_count} nodes</div>
            </div>
            `
          })

          popupContents += '</div>'
          const coordinates = e.features[0].geometry.coordinates.slice()

          // Ensure that the popup always appears over the clicked Node
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
          }

          nodeInfoPopup.setLngLat(coordinates).setHTML(popupContents).addTo(map)
        })
      }).catch(error => {
        document.body.dataset.notificationsAlertValue = error
        try {
          document.getElementById('reset-node-hunter').remove()
          nodeInfoPopup.remove()

          if (map.getLayer('CityStrides-unfinishedNodes')) {
            map.removeLayer('CityStrides-unfinishedNodes')
            map.removeSource('CityStrides-unfinishedNodes')
          }
        } catch (error) {
        }
      })
    }, { passive: true })

    return this._container
  }

  onRemove () {
    this._container.parentNode.removeChild(this._container)
    this._map = undefined
  }
}

export default NodeHunterControl
