import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static outlets = ['map']

  mapOutletConnected (outlet, _element) {
    if (typeof outlet.map === 'undefined') {
      outlet.initializeTheMap()
    }

    outlet.adjustClassesRegular()

    if (outlet.mapIsInitialized) {
      this.positionTheView()
    } else {
      outlet.map.once('style.load', () => {
        this.positionTheView()
      })
    }
  }

  mapOutletDisconnected (outlet, _element) {
    outlet.removeAllCities()
  }

  positionTheView () {
    this.mapOutlet.map.resize()
    this.mapOutlet.map.jumpTo({ center: [29, 29], zoom: 0, preload: true })
  }
}
