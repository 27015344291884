import { Controller } from '@hotwired/stimulus'
import bbox from '@turf/bbox'

export default class extends Controller {
  static outlets = ['map']

  static values = {
    center: Array,
    challengeCityId: Number,
    challengeDescription: String,
    challengeEndAt: String,
    challengeStartAt: String,
    challengeStyle: String,
    geoJsonUrl: String,
    halloween: Boolean,
    tileUrl: String,
    userId: String
  }

  mapOutletConnected (outlet, _element) {
    if (typeof outlet.map === 'undefined') {
      outlet.initializeTheMap()
    }
    this.currentYear = new Date().getFullYear()
    outlet.adjustClassesRegular()

    if (outlet.mapIsInitialized) {
      this.addCity()
      this.addChallengeMap(outlet)
      this.filterLifeMap()
      this.setHalloweenMap()
    } else {
      outlet.map.once('style.load', () => {
        this.addCity()
        this.addChallengeMap(outlet)
        this.filterLifeMap()
        this.setHalloweenMap()
      })
    }
  }

  mapOutletDisconnected (outlet, _element) {
    if (outlet.map.getLayer('CityStrides-challengeMap')) {
      outlet.removeLayerAndSource('CityStrides-challengeMap')
    }

    if (outlet.map.getLayer('CityStrides-cityBorder')) {
      outlet.removeLayerAndSource('CityStrides-cityBorder')
    }

    if (outlet.map.getLayer('CityStrides-selfLifeMap')) {
      outlet.map.setFilter('CityStrides-selfLifeMap', null)
      outlet.map.setLayoutProperty('CityStrides-selfLifeMap', 'visibility', 'none')
      outlet.toggleLifeMapControl.buttonUnhighlight()
    }

    if (this.halloweenValue) {
      outlet.mapStyleValue = outlet.regularStyleLink
      outlet.changeMapStyle()
    }
  }

  addChallengeMap (outlet) {
    if (this.challengeStyleValue !== 'collaborative') {
      return
    }
    if (outlet.map.getLayer('CityStrides-challengeMap')) {
      return
    }

    const developmentTiles = [`http://localhost:9000/challenge_public/${this.tileUrlValue}/{z}/{x}/{y}`]
    const productionTiles = [
      `https://tiles-a.citystrides.com/challenge_public/${this.tileUrlValue}/{z}/{x}/{y}`,
      `https://tiles-b.citystrides.com/challenge_public/${this.tileUrlValue}/{z}/{x}/{y}`,
      `https://tiles-c.citystrides.com/challenge_public/${this.tileUrlValue}/{z}/{x}/{y}`
    ]
    const tileSources = process.env.NODE_ENV === 'development' ? developmentTiles : productionTiles
    outlet.map.addSource('CityStrides-challengeMap', {
      type: 'vector',
      maxzoom: 10,
      tiles: tileSources
    })
    outlet.map.addLayer({
      id: 'CityStrides-challengeMap',
      type: 'line',
      source: 'CityStrides-challengeMap',
      'source-layer': this.tileUrlValue, // `${challengeId}_${tile_token}`
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-sort-key': ['get', 'start_time'],
        visibility: 'visible'
      },
      paint: {
        'line-color': '#8B5CF6',
        'line-width': 2
      }
    }, 'road-label')
  }

  addCity () {
    this.mapOutlet.map.resize()

    if (this.challengeCityIdValue === '' || this.challengeCityIdValue === 0) {
      this.mapOutlet.map.jumpTo({ center: this.centerValue, zoom: 1.1, preload: true })
      return
    }

    // duplicate code in cities_controller.js & map_city_controller.js & map_challenge_controller.js
    window.fetch(this.geoJsonUrlValue, { credentials: 'same-origin' }).then(response => {
      if (response.status >= 200 && response.status <= 299) {
        return response.json()
      } else {
        throw Error('😱 There was an error when retrieving the city border')
      }
    }).then(data => {
      const boundingBox = bbox(data)
      this.mapOutlet.map.addSource('CityStrides-cityBorder', { type: 'geojson', data })
      this.mapOutlet.map.addLayer({
        id: 'CityStrides-cityBorder',
        type: 'line',
        source: 'CityStrides-cityBorder',
        paint: {
          'line-color': '#60a5fa',
          'line-width': 5
        }
      }, this.mapOutlet.map.getLayer('CityStrides-selfLifeMap') ? 'CityStrides-selfLifeMap' : 'road-label')
      this.mapOutlet.map.fitBounds(boundingBox, { animate: false, preload: true, padding: 40 })
    }).catch(error => {
      this.mapOutlet.map.jumpTo({ center: this.centerValue, zoom: 1.1, preload: true })
      this.dispatch('toast', {
        prefix: 'notifications',
        detail: { content: error, type: 'error' }
      })
      this.application.handleError(error)
    })
  }

  filterLifeMap () {
    if (this.challengeStartAtValue === '') {
      return
    }

    if (this.mapOutlet.map.getLayer('CityStrides-selfLifeMap')) {
      this.mapOutlet.map.setFilter(
        'CityStrides-selfLifeMap',
        [
          'all',
          ['>=', 'start_time', Date.parse(`${this.challengeStartAtValue}T00:00:00`)],
          ['<=', 'start_time', Date.parse(`${this.challengeEndAtValue}T23:59:59`)]
        ]
      )
      this.mapOutlet.map.setLayoutProperty('CityStrides-selfLifeMap', 'visibility', 'visible')
      this.mapOutlet.toggleLifeMapControl.buttonHighlight()
    }
  }

  setHalloweenMap () {
    if (!this.halloweenValue) {
      return
    }

    this.mapOutlet.mapStyleValue = this.mapOutlet.halloweenStyleLink
    this.mapOutlet.changeMapStyle()
  }
}
