import { Application } from '@hotwired/stimulus'
import consumer from '../channels/consumer'
import * as Sentry from '@sentry/browser'

// Configure GlitchTip
Sentry.init({
  dsn: 'https://8f61f1792f3b48d393eaa8078c46e46e@errors.citystrides.com/2',
  ignoreErrors: [/The user aborted a request/, /Fetch is aborted/, /this.properties is undefined/, /Failed to initialize WebGL/],
  tracesSampleRate: 0.01
})
Sentry.setUser({ id: window.loggedInUserId })

const application = Application.start()
// Configure Stimulus development experience
application.consumer = consumer
application.debug = process.env.NODE_ENV === 'development'
window.Stimulus = application

// Set up error handler
application.handleError = (error, message, detail) => {
  console.warn(error, message, detail)
  Sentry.captureException(error)
}

export { application }
