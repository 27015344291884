import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static outlets = ['map']

  mapOutletConnected (outlet, _element) {
    if (typeof outlet.map === 'undefined') {
      outlet.initializeTheMap()
    }

    const standingsFrame = document.querySelector('turbo-frame#nordvik-top-five')
    const topFiveFrame = document.querySelector('turbo-frame#nordvik-top-five')
    outlet.adjustClassesSpecial()

    if (outlet.mapIsInitialized) {
      this.positionTheView()
      this.addNordvikMap(outlet)
    } else {
      outlet.map.once('style.load', () => {
        this.positionTheView()
        this.addNordvikMap(outlet)
      })
    }

    this.interval = setInterval(() => {
      standingsFrame.reload()
      topFiveFrame.reload()
    }, 60000)
  }

  mapOutletDisconnected (outlet, _element) {
    clearInterval(this.interval)

    if (outlet.map.getLayer('CityStrides-NordvikMap')) {
      outlet.removeLayerAndSource('CityStrides-NordvikMap')
    }
  }

  addNordvikMap (outlet) {
    const developmentTiles = ['http://localhost:9000/challenge_public/nordvik_map/{z}/{x}/{y}']
    const productionTiles = [
      'https://tiles-a.citystrides.com/challenge_public/nordvik_map/{z}/{x}/{y}',
      'https://tiles-b.citystrides.com/challenge_public/nordvik_map/{z}/{x}/{y}',
      'https://tiles-c.citystrides.com/challenge_public/nordvik_map/{z}/{x}/{y}'
    ]
    const tileSources = process.env.NODE_ENV === 'development' ? developmentTiles : productionTiles
    outlet.map.addSource('CityStrides-NordvikMap', {
      type: 'vector',
      maxzoom: 10,
      tiles: tileSources
    })
    outlet.map.addLayer({
      id: 'CityStrides-NordvikMap',
      type: 'line',
      source: 'CityStrides-NordvikMap',
      'source-layer': 'nordvik',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible'
      },
      paint: {
        'line-color': '#8B5CF6',
        'line-width': 2
      }
    }, 'road-label')
  }

  positionTheView () {
    this.mapOutlet.map.resize()
    this.mapOutlet.map.jumpTo({ center: [14.77, 64.26], zoom: 3, preload: true })
  }
}
