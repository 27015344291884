import { Controller } from '@hotwired/stimulus'
import bbox from '@turf/bbox'

export default class extends Controller {
  static outlets = ['map']

  mapOutletConnected (outlet, _element) {
    try {
      this.addButton()
    } catch {
      // It's ok to do nothing because the default state of the button is good for the user's first visit
    }
  }

  mapOutletDisconnected (_outlet, _element) {
    // removal is done via map_*_controller.js
  }

  showCity (event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    const thisTarget = event.currentTarget
    thisTarget.setAttribute('data-action', 'cities#hideCity')
    thisTarget.innerHTML = 'Hide'
    const id = thisTarget.getAttribute('data-id')
    const geoJsonUrl = `https://geojson.citystrides.com/${thisTarget.getAttribute('data-country-id')}/${thisTarget.getAttribute('data-region-id')}/${thisTarget.getAttribute('data-id')}.geojson`

    // duplicate code in cities_controller.js & map_city_controller.js & map_challenge_controller.js
    window.fetch(geoJsonUrl, { credentials: 'same-origin' }).then(response => {
      if (response.status >= 200 && response.status <= 299) {
        return response.json()
      } else {
        throw Error('😱 There was an error when retrieving the city border')
      }
    }).then(data => {
      const boundingBox = bbox(data)
      this.mapOutlet.map.addSource(`CityStrides-cityBorder${id}`, { type: 'geojson', data })
      this.mapOutlet.map.addLayer({
        id: `CityStrides-cityBorder${id}`,
        type: 'line',
        source: `CityStrides-cityBorder${id}`,
        paint: {
          'line-color': '#60a5fa',
          'line-width': 5
        }
      }, 'road-label')
      this.mapOutlet.map.fitBounds(boundingBox, { preload: true, padding: 40 })

      try {
        document.getElementById('hide-all-cities').classList.remove('hidden')
      } catch {
        console.log('hide-all-cities button not found')
      }
    }).catch(error => {
      this.dispatch('toast', {
        prefix: 'notifications',
        detail: { content: error, type: 'error' }
      })
      this.application.handleError(error)
    })
  }

  hideAllCities (event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    document.querySelectorAll('.show-hide-city').forEach(function (button) {
      button.setAttribute('data-action', 'cities#showCity')
      button.innerHTML = 'Show'
    })
    // TODO: this will remove the parent city if using this in the nested city list
    this.mapOutlet.removeAllCities()
    document.getElementById('hide-all-cities').classList.add('hidden')
  }

  hideCity (event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    let doNotRemoveButton = false
    const thisTarget = event.currentTarget
    thisTarget.setAttribute('data-action', 'cities#showCity')
    thisTarget.innerHTML = 'Show'
    this.mapOutlet.removeLayerAndSource(`CityStrides-cityBorder${thisTarget.getAttribute('data-id')}`)

    this.mapOutlet.map.getStyle().layers.forEach((layer) => {
      if (layer.id.includes('CityStrides-cityBorder')) {
        doNotRemoveButton = true
      }
    })
    if (!doNotRemoveButton) {
      document.getElementById('hide-all-cities').classList.add('hidden')
    }
  }

  addButton () {
    let showButton = false
    this.mapOutlet.map.getStyle().layers.forEach((layer) => {
      if (layer.id.includes('CityStrides-cityBorder')) {
        showButton = true
      }
    })
    if (showButton) {
      document.getElementById('hide-all-cities').classList.remove('hidden')
    }
  }
}
