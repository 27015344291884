import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static outlets = ['map']

  static values = {
    markersPath: String,
    streamedMarkers: Array
  }

  mapOutletConnected (outlet, _element) {
    if (typeof outlet.map === 'undefined') {
      outlet.initializeTheMap()
    }

    const frame = document.querySelector('turbo-frame#featured-challenge-top-five')
    outlet.adjustClassesSpecial()

    if (outlet.mapIsInitialized) {
      this.positionTheView()
      this.addMarkers()
    } else {
      outlet.map.once('style.load', () => {
        this.positionTheView()
        this.addMarkers()
      })
    }

    this.interval = setInterval(() => {
      frame.reload()
    }, 60000)
  }

  mapOutletDisconnected (outlet, _element) {
    clearInterval(this.interval)

    if (outlet.map.getLayer('CityStrides-FeaturedChallenge')) {
      outlet.map.removeLayer('CityStrides-FeaturedChallenge-count')
      outlet.removeLayerAndSource('CityStrides-FeaturedChallenge')
    }

    outlet.map.off('click', 'CityStrides-FeaturedChallenge', this.clickStrider)
    outlet.map.off('mousemove', 'CityStrides-FeaturedChallenge', this.hoverStrider)
    outlet.map.off('mouseleave', 'CityStrides-FeaturedChallenge', this.unHoverStrider)
  }

  addMarkers () {
    if (this.markersPathValue === '') {
      return
    }

    const request = new FetchRequest('get', this.markersPathValue)
    request.perform().then(response => {
      return response.json
    }).then(data => {
      if (!this.mapOutlet.map.getSource('CityStrides-FeaturedChallenge')) {
        this.mapOutlet.map.addSource('CityStrides-FeaturedChallenge', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: data
          },
          cluster: true,
          clusterRadius: 20
        })
      }
      if (!this.mapOutlet.map.getLayer('CityStrides-FeaturedChallenge')) {
        this.mapOutlet.map.addLayer({
          id: 'CityStrides-FeaturedChallenge',
          type: 'circle',
          source: 'CityStrides-FeaturedChallenge',
          paint: {
            'circle-stroke-color': '#fff',
            'circle-stroke-width': 1,
            'circle-color': [
              'match',
              ['get', 'custom-color'],
              'purple',
              '#4c1d95',
              'red',
              '#ef4444',
              /* other */ '#3b82f6'
            ],
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              9,
              100,
              15,
              1000,
              20
            ]
          }
        })
        this.mapOutlet.map.addLayer({
          id: 'CityStrides-FeaturedChallenge-count',
          type: 'symbol',
          source: 'CityStrides-FeaturedChallenge',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-size': 12
          },
          paint: {
            'text-color': 'white'
          }
        })
      }
    }).catch(error => {
      this.dispatch('toast', {
        prefix: 'notifications',
        detail: { content: error, type: 'error' }
      })
      this.application.handleError(error)
    })

    this.mapOutlet.map.on('click', 'CityStrides-FeaturedChallenge', this.clickStrider)
    this.mapOutlet.map.on('mousemove', 'CityStrides-FeaturedChallenge', this.hoverStrider)
    this.mapOutlet.map.on('mouseleave', 'CityStrides-FeaturedChallenge', this.unHoverStrider)
  }

  clickStrider = (e) => {
    const features = this.mapOutlet.map.queryRenderedFeatures(e.point, { layers: ['CityStrides-FeaturedChallenge'] })
    const clusterId = features[0].properties.cluster_id

    if (clusterId) {
      this.mapOutlet.map.getSource('CityStrides-FeaturedChallenge').getClusterExpansionZoom(
        clusterId,
        (err, zoom) => {
          if (err) return

          this.mapOutlet.map.flyTo({
            center: features[0].geometry.coordinates,
            zoom,
            preload: true
          })
        }
      )
    } else {
      // this.popups.push(new mapboxgl.Popup().setLngLat(e.lngLat).setHTML(e.features[0].properties.description).addTo(this.mapOutlet.map))
    }
  }

  hoverStrider () {
    this.getCanvas().style.cursor = 'pointer'
  }

  positionTheView () {
    this.mapOutlet.map.resize()
    this.mapOutlet.map.jumpTo({ center: [29, 29], zoom: 0, preload: true })
  }

  // StreamActions.streamed_markers
  streamedMarkersValueChanged () {
    // this hack handles the fact that ValueChanged is always called on load
    if (this.hasMapOutlet === false) {
      return
    }
    if (typeof this.mapOutlet.map === 'undefined' || typeof this.mapOutlet.map.getSource('CityStrides-FeaturedChallenge') === 'undefined') {
      return
    }

    const mapSource = this.mapOutlet.map.getSource('CityStrides-FeaturedChallenge')
    const features = mapSource._data.features
    for (let i = 0; i < this.streamedMarkersValue.length; i++) {
      features.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: this.streamedMarkersValue[i]
        },
        properties: {
          'custom-color': 'purple'
        }
      })
    }

    mapSource.setData({ type: 'FeatureCollection', features })
  }

  unHoverStrider () {
    this.getCanvas().style.cursor = 'grab'
  }
}
