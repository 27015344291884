// Button to toggle CityMap
class ToggleCityMapControl {
  constructor (mapboxgl, supporter) {
    this._mapboxgl = mapboxgl
    this._supporter = supporter
  }

  onAdd (map) {
    this._map = map
    this._container = document.createElement('div')
    this._container.classList.add('mapboxgl-ctrl')
    this._container.classList.add('mapboxgl-ctrl-group')
    this._button = document.createElement('button')
    this._button.classList.add('mapboxgl-ctrl-icon')
    this._button.classList.add('plausible-event-name=ToggleCityMapControl')
    this._button.setAttribute('title', 'Toggle CityMap')
    this._button.innerHTML = '<svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" stroke="currentColor" class="text-purple-900 w-6 h-6 pl-1"><path d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>'
    this._container.appendChild(this._button)
    this._cityInfoPopup = new this._mapboxgl.Popup({ closeButton: false })
    this.addCityMap()

    this._button.addEventListener('debounced:click', () => {
      if (!this._supporter) {
        window.Turbo.visit('/supporter-features/global-borders')
      } else if (this._map.getLayoutProperty('CityStrides-cityMap', 'visibility') === 'visible') {
        this._map.setLayoutProperty('CityStrides-cityMap', 'visibility', 'none')
      } else {
        this._map.setLayoutProperty('CityStrides-cityMap', 'visibility', 'visible')
      }
    }, { passive: true })

    return this._container
  }

  onRemove () {
    this._container.parentNode.removeChild(this._container)
    this._map = undefined
  }

  hoverCity (e) {
    this._map.getCanvas().style.cursor = 'pointer'
    this.hoveredCityIds = e.features.map(feature => feature.id)

    this.hoveredCityIds.forEach(id => {
      this._map.setFeatureState(
        {
          source: 'CityStrides-cityMap',
          sourceLayer: 'cities',
          id
        },
        { hover: true }
      )
    })
  }

  unHover () {
    this._map.getCanvas().style.cursor = 'grab'

    const features = this._map.queryRenderedFeatures({ layers: ['CityStrides-cityMap'] })

    features.forEach(feature => {
      this._map.setFeatureState(
        {
          source: 'CityStrides-cityMap',
          sourceLayer: 'cities',
          id: feature.id
        },
        { hover: false }
      )
    })

    this.hoveredCityIds = null
  }

  clickCity (e) {
    if (document.getElementById('mapContainer').getAttribute('data-map-currently-drawing-value') === 'true') {
      // don't animate the LifeMap if the person is drawing a route
      return
    }

    let popupContents = '<div class="space-y-4">'

    Object.values(e.features).forEach((val) => {
      popupContents += `
      <div>
        <div class="text-sm font-semibold">
          <a class="text-link" href="https://citystrides.com/cities/${val.properties.city_id}">${val.properties.name}</a>
        </div>
      </div>
      `
    })

    popupContents += '</div>'
    this._cityInfoPopup.setLngLat(e.lngLat).setHTML(popupContents).addTo(this._map)
  }

  addCityMap () {
    if (this._map.getLayer('CityStrides-cityMap')) {
      return
    }

    this._map.addSource('CityStrides-cityMap', {
      type: 'vector',
      maxzoom: 10,
      tiles: [
        'https://tiles-a.citystrides.com/tiles_public/cities/{z}/{x}/{y}',
        'https://tiles-b.citystrides.com/tiles_public/cities/{z}/{x}/{y}',
        'https://tiles-c.citystrides.com/tiles_public/cities/{z}/{x}/{y}'
      ]
    })
    this._map.addLayer({
      id: 'CityStrides-cityMap',
      type: 'line',
      source: 'CityStrides-cityMap',
      'source-layer': 'cities',
      layout: {
        visibility: 'none'
      },
      paint: {
        'line-color': '#d946ef',
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          9,
          7
        ]
      }
    }, 'road-label')

    this._map.on('click', 'CityStrides-cityMap', (e) => { this.clickCity(e) })
    this._map.on('mousemove', 'CityStrides-cityMap', (e) => { this.hoverCity(e) })
    this._map.on('mouseleave', 'CityStrides-cityMap', () => { this.unHover() })
  }
}

export default ToggleCityMapControl
